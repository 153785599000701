// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading-in-modal-button .lds-dual-ring:after {
  content: " ";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px solid #fff;
  border-color: #fff transparent #fff transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.create-update-modal-form .dropdown-input .dropdown-body .loading-in-modal-button .lds-dual-ring:after {
  width: 20px;
  height: 20px;
  border: 4px solid var(--primary-500);
  border-color: var(--primary-500) transparent var(--primary-500) transparent;
}



.loading-in-modal-button.excel .lds-dual-ring:after {
  content: " ";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 3px solid var(--primary-500);
  border-color: var(--primary-500) transparent var(--primary-500) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}
.create-update-modal-form .dropdown-input .dropdown-body .loading-in-modal-button.excel .lds-dual-ring:after {
  width: 20px;
  height: 20px;
  border: 4px solid var(--primary-500);
  border-color: var(--primary-500) transparent var(--primary-500) transparent;
}

@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
`, "",{"version":3,"sources":["webpack://./src/globalComponents/Loading/components/LoadingBtn/loadingBtn.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,sBAAsB;EACtB,+CAA+C;EAC/C,6CAA6C;AAC/C;AACA;EACE,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,2EAA2E;AAC7E;;;;AAIA;EACE,YAAY;EACZ,cAAc;EACd,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,oCAAoC;EACpC,2EAA2E;EAC3E,6CAA6C;AAC/C;AACA;EACE,WAAW;EACX,YAAY;EACZ,oCAAoC;EACpC,2EAA2E;AAC7E;;AAEA;EACE;IACE,uBAAuB;EACzB;EACA;IACE,yBAAyB;EAC3B;AACF","sourcesContent":[".loading-in-modal-button .lds-dual-ring:after {\n  content: \" \";\n  display: block;\n  width: 15px;\n  height: 15px;\n  border-radius: 50%;\n  border: 3px solid #fff;\n  border-color: #fff transparent #fff transparent;\n  animation: lds-dual-ring 1.2s linear infinite;\n}\n.create-update-modal-form .dropdown-input .dropdown-body .loading-in-modal-button .lds-dual-ring:after {\n  width: 20px;\n  height: 20px;\n  border: 4px solid var(--primary-500);\n  border-color: var(--primary-500) transparent var(--primary-500) transparent;\n}\n\n\n\n.loading-in-modal-button.excel .lds-dual-ring:after {\n  content: \" \";\n  display: block;\n  width: 15px;\n  height: 15px;\n  border-radius: 50%;\n  border: 3px solid var(--primary-500);\n  border-color: var(--primary-500) transparent var(--primary-500) transparent;\n  animation: lds-dual-ring 1.2s linear infinite;\n}\n.create-update-modal-form .dropdown-input .dropdown-body .loading-in-modal-button.excel .lds-dual-ring:after {\n  width: 20px;\n  height: 20px;\n  border: 4px solid var(--primary-500);\n  border-color: var(--primary-500) transparent var(--primary-500) transparent;\n}\n\n@keyframes lds-dual-ring {\n  0% {\n    transform: rotate(0deg);\n  }\n  100% {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
