import React, { useEffect, useState } from "react";
import UpdateDeleteModal from "../../../../globalComponents/Modals/UpdateDeleteModal/UpdateDeleteModal";
import SalaryMoreModal from "../../../../globalComponents/MoreModal/SalaryMoreModal/SalaryMoreModal";
// import SalaryMoreModal from "../../../../globalComponents/MoreModal/components/SalaryMoreModal/SalaryMoreModal";
// import SalaryBonusModal from "../SalaryBonusModal/SalaryBonusModal";
// import SalaryEditModal from "../SalaryBonusModal/components/SalaryEditModal/SalaryEditModal";

const SalaryCard = ({ salary, mode, cellNumber }) => {
  const [bonusEditModal, setBonusEditModal] = useState(false);
  const [salaryMoreModal, setSalaryMoreModal] = useState(false);
  const [openBonusModal, setOpenBonusModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [openSalaryModal, setOpenSalaryModal] = useState(false);

  const listData = [
    {
      key: "Əmək haqqı",
      value: salary?.salary
        ? `${salary?.salary?.value ? salary?.salary?.value : ""} ${
            salary?.salary?.hourly === true ? "(saatlıq)" : "(aylıq)"
          }`
        : "boş",
    },

    {
      key: "Toplam əmək haqqı",
      value: salary?.totalSalary,
    },
  ];

  const updateItem = (modalType) => {};
  const deleteItem = () => {};

  const openConfirmModal = () => {
    // dispatch({
    //   type: TUITION_FEE_MODAL_ACTION_TYPE.UPDATE_TUITION_FEE_PAYMENTS,
    //   payload: {
    //     data: data,
    //     openModal: false,
    //     openConfirmModal: true,
    //   },
    // });
  };

  const openMoreModal = () => {
    // updateItem("more");
    // setOpenMoreModal(true);
  };

  useEffect(() => {
    if (salaryMoreModal) {
      document.body.style.overflowY = "hidden";
    } else if (openBonusModal && window.innerWidth <= 1280) {
      document.body.style.overflowY = "hidden";
    } else if (bonusEditModal && window.innerWidth <= 1280) {
      document.body.style.overflowY = "hidden";
    } else {
      document.body.style.overflowY = "overlay";
    }
  }, [salaryMoreModal, openBonusModal, bonusEditModal]);

  console.log("salary:", salary);
  return (
    <>
      {mode === "desktop" ? (
        <>
          {openSalaryModal && (
            <SalaryMoreModal
              salary={salary}
              setOpenSalaryModal={setOpenSalaryModal}
            />
          )}

          <tr>
            <td>
              <div className="td-con">
                <div className="cell-number">{cellNumber}.</div>
                <div className="table-scroll-text">
                  {salary?.teacher.fullName}
                </div>
                <div className="right-fade"></div>
              </div>
            </td>
            <td>
              <div className="td-con">
                <div className="table-scroll-text no-wrap">
                  {salary?.salary} AZN
                </div>
              </div>
            </td>
            <td>
              <div className="td-con">
                <div
                  className="table-scroll-text no-wrap"
                  onClick={() => setOpenSalaryModal(true)}
                >
                  Ətraflı...
                </div>
              </div>
            </td>
            <td>
              <UpdateDeleteModal
                updateItem={updateItem}
                deleteItem={deleteItem}
                data={salary}
                openMoreModal={openMoreModal}
                openConfirmModal={openConfirmModal}
                profil={"salaries"}
              />
            </td>
          </tr>
        </>
      ) : (
        <>
          {/* {salaryMoreModal && (
            <SalaryMoreModal
              setSalaryMoreModal={setSalaryMoreModal}
              setBonusEditModal={setBonusEditModal}
              salary={salary}
              setOpenBonusModal={setOpenBonusModal}
            />
          )} */}
          <div className="content-box">
            <div className="left">
              <h3>{salary?.teacherName}</h3>
              <ul>
                {listData.map((item, index) => (
                  <li key={index}>
                    <span className="type">{item.key}:</span>
                    <p>{item.value}</p>
                  </li>
                ))}
              </ul>
            </div>
            <div className="right">
              <div
                onClick={() => setSalaryMoreModal(true)}
                className="salary-tablet-modal"
              >
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g id="Icons/Line/Arrows/chevron-right">
                    <path
                      id="Icon"
                      d="M9 18L15 12L9 6"
                      stroke="#717171"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </div>
            </div>
            {/* {openBonusModal && (
              <div className="salary-bonus-modal-tablet">
                <SalaryBonusModal
                  salary={salary}
                  setOpenBonusModal={setOpenBonusModal}
                  openBonusModal={openBonusModal}
                />
              </div>
            )} */}

            {/* {bonusEditModal && (
              <div className="salary-bonus-modal-tablet">
                <SalaryEditModal
                  salary={salary}
                  setBonusEditModal={setBonusEditModal}
                  bonusEditModal={bonusEditModal}
                />
              </div>
            )} */}
          </div>
        </>
      )}
    </>
  );
};

export default SalaryCard;
