// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tuition-fee-page .table-con {
  /* overflow-x: auto;
    overflow-y: hidden; */
}

/* .tuition-fee-page .table-con .details-table {
  table-layout: unset;
} */
`, "",{"version":3,"sources":["webpack://./src/assets/css/pages/tuitionFee.css"],"names":[],"mappings":"AAAA;EACE;yBACuB;AACzB;;AAEA;;GAEG","sourcesContent":[".tuition-fee-page .table-con {\n  /* overflow-x: auto;\n    overflow-y: hidden; */\n}\n\n/* .tuition-fee-page .table-con .details-table {\n  table-layout: unset;\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
