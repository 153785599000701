// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.finance-page .finance-chart .apexcharts-legend {
  inset: unset !important;
  left: 0 !important;
  bottom: 0 !important;
}
.finance-page .finance-chart .chart-legends {
  display: flex;
  margin-top: 24px;
}
.finance-page .finance-chart .chart-legends .legend {
  color: var(--neutrals-900);
  font-size: 14px;
  font-weight: 500;
  border-radius: 4px;
  padding: 4px 24px;
  margin-right: 12px;
  cursor: pointer;
}
.finance-page .finance-chart .chart-legends .legend:last-child {
  margin-right: 0;
}
.finance-page .finance-chart .chart-legends .legend.blue {
  border: 1px solid var(--secondary-600);
  background: rgba(5, 165, 234, 0.1);
}
.finance-page .finance-chart .chart-legends .legend.red {
  border: 1px solid var(--error-600);
  background: rgba(240, 59, 42, 0.1);
}
.finance-page .finance-chart .chart-legends .legend.yellow {
  border: 1px solid var(--tertiary-700);
  background: rgba(251, 160, 19, 0.1);
}
.finance-page .finance-chart .chart-legends .legend.green {
  border: 1px solid #00bc85;
  background: rgba(0, 188, 133, 0.1);
}

@media screen and (max-width: 640px) {
  .finance-page .finance-chart .chart-legends {
    margin-top: 16px;
  }
  .finance-page .finance-chart .chart-legends .legend {
    font-size: 12px;
    padding: 4px 12px;
  }

}`, "",{"version":3,"sources":["webpack://./src/Pages/SalesPage/components/FinanceChart/financeChart.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,kBAAkB;EAClB,oBAAoB;AACtB;AACA;EACE,aAAa;EACb,gBAAgB;AAClB;AACA;EACE,0BAA0B;EAC1B,eAAe;EACf,gBAAgB;EAChB,kBAAkB;EAClB,iBAAiB;EACjB,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,eAAe;AACjB;AACA;EACE,sCAAsC;EACtC,kCAAkC;AACpC;AACA;EACE,kCAAkC;EAClC,kCAAkC;AACpC;AACA;EACE,qCAAqC;EACrC,mCAAmC;AACrC;AACA;EACE,yBAAyB;EACzB,kCAAkC;AACpC;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;IACE,eAAe;IACf,iBAAiB;EACnB;;AAEF","sourcesContent":[".finance-page .finance-chart .apexcharts-legend {\n  inset: unset !important;\n  left: 0 !important;\n  bottom: 0 !important;\n}\n.finance-page .finance-chart .chart-legends {\n  display: flex;\n  margin-top: 24px;\n}\n.finance-page .finance-chart .chart-legends .legend {\n  color: var(--neutrals-900);\n  font-size: 14px;\n  font-weight: 500;\n  border-radius: 4px;\n  padding: 4px 24px;\n  margin-right: 12px;\n  cursor: pointer;\n}\n.finance-page .finance-chart .chart-legends .legend:last-child {\n  margin-right: 0;\n}\n.finance-page .finance-chart .chart-legends .legend.blue {\n  border: 1px solid var(--secondary-600);\n  background: rgba(5, 165, 234, 0.1);\n}\n.finance-page .finance-chart .chart-legends .legend.red {\n  border: 1px solid var(--error-600);\n  background: rgba(240, 59, 42, 0.1);\n}\n.finance-page .finance-chart .chart-legends .legend.yellow {\n  border: 1px solid var(--tertiary-700);\n  background: rgba(251, 160, 19, 0.1);\n}\n.finance-page .finance-chart .chart-legends .legend.green {\n  border: 1px solid #00bc85;\n  background: rgba(0, 188, 133, 0.1);\n}\n\n@media screen and (max-width: 640px) {\n  .finance-page .finance-chart .chart-legends {\n    margin-top: 16px;\n  }\n  .finance-page .finance-chart .chart-legends .legend {\n    font-size: 12px;\n    padding: 4px 12px;\n  }\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
