// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.finance-page .finance-top {
  padding: 24px;
  display: flex;
  overflow: hidden;
  flex-direction: column;
}
.finance-page .finance-top > .left {
  /* width: 75%; */
  /* margin-right: 36px; */
}
.finance-page .finance-top > .right {
  /* width: 25%; */
  margin-top: 20px;
}
.finance-page .finance-statictics{
  display: grid;
  grid-template-columns: repeat(2,1fr);
}

@media screen and (max-width: 1110px) {
  .finance-page .finance-top {
    flex-direction: column;
    padding: 16px;
  }
  .finance-page .finance-top > .left {
    width: 100%;
    margin-right: 0;
    margin-bottom: 36px;
  }
  .finance-page .finance-top > .right {
    width: 100%;
  }
}

@media screen and (max-width: 640px) {
  .finance-page .finance-top > .left {
    margin-bottom: 24px;
  }
  .finance-page .finance-top {
    padding: 16px 12px;
  }
}

@media screen and (max-width: 450px) {
  .finance-page .finance-top {
    padding-right: 0;
  }  
  .finance-page .finance-chart,
  .finance-page .finance-top > .right {
    padding-right: 12px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/pages/financePage.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,aAAa;EACb,gBAAgB;EAChB,sBAAsB;AACxB;AACA;EACE,gBAAgB;EAChB,wBAAwB;AAC1B;AACA;EACE,gBAAgB;EAChB,gBAAgB;AAClB;AACA;EACE,aAAa;EACb,oCAAoC;AACtC;;AAEA;EACE;IACE,sBAAsB;IACtB,aAAa;EACf;EACA;IACE,WAAW;IACX,eAAe;IACf,mBAAmB;EACrB;EACA;IACE,WAAW;EACb;AACF;;AAEA;EACE;IACE,mBAAmB;EACrB;EACA;IACE,kBAAkB;EACpB;AACF;;AAEA;EACE;IACE,gBAAgB;EAClB;EACA;;IAEE,mBAAmB;EACrB;AACF","sourcesContent":[".finance-page .finance-top {\n  padding: 24px;\n  display: flex;\n  overflow: hidden;\n  flex-direction: column;\n}\n.finance-page .finance-top > .left {\n  /* width: 75%; */\n  /* margin-right: 36px; */\n}\n.finance-page .finance-top > .right {\n  /* width: 25%; */\n  margin-top: 20px;\n}\n.finance-page .finance-statictics{\n  display: grid;\n  grid-template-columns: repeat(2,1fr);\n}\n\n@media screen and (max-width: 1110px) {\n  .finance-page .finance-top {\n    flex-direction: column;\n    padding: 16px;\n  }\n  .finance-page .finance-top > .left {\n    width: 100%;\n    margin-right: 0;\n    margin-bottom: 36px;\n  }\n  .finance-page .finance-top > .right {\n    width: 100%;\n  }\n}\n\n@media screen and (max-width: 640px) {\n  .finance-page .finance-top > .left {\n    margin-bottom: 24px;\n  }\n  .finance-page .finance-top {\n    padding: 16px 12px;\n  }\n}\n\n@media screen and (max-width: 450px) {\n  .finance-page .finance-top {\n    padding-right: 0;\n  }  \n  .finance-page .finance-chart,\n  .finance-page .finance-top > .right {\n    padding-right: 12px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
