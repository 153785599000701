// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.not-found-page{
    /* margin-top: 56px; */
    height: 100vh;
}
.not-found-page .container{
    height: 100%;
}
.not-found-page .container .not-found-page-component{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
}
.not-found-page .container .not-found-page-component p{
    margin-top: 36px;
    color: #1E1E1E;
    font-size: 28px;
    font-weight: 700;
}
.not-found-page .container .not-found-page-component .return-btn {
    padding: 16px 24px;
    background-color: #462AFF;
    color: white;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 36px;
    font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/Pages/NotFoundPage/notFoundPage.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,aAAa;AACjB;AACA;IACI,YAAY;AAChB;AACA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,sBAAsB;IACtB,YAAY;AAChB;AACA;IACI,gBAAgB;IAChB,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".not-found-page{\n    /* margin-top: 56px; */\n    height: 100vh;\n}\n.not-found-page .container{\n    height: 100%;\n}\n.not-found-page .container .not-found-page-component{\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    height: 100%;\n}\n.not-found-page .container .not-found-page-component p{\n    margin-top: 36px;\n    color: #1E1E1E;\n    font-size: 28px;\n    font-weight: 700;\n}\n.not-found-page .container .not-found-page-component .return-btn {\n    padding: 16px 24px;\n    background-color: #462AFF;\n    color: white;\n    border-radius: 8px;\n    cursor: pointer;\n    margin-top: 36px;\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
