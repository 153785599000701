// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:where(.css-dev-only-do-not-override-1jr9qlj).ant-pagination
  .ant-pagination-options {
  display: none;
}
:where(.css-dev-only-do-not-override-1jr9qlj).ant-pagination
  .ant-pagination-prev,
:where(.css-dev-only-do-not-override-1jr9qlj).ant-pagination
  .ant-pagination-next,
:where(.css-dev-only-do-not-override-1jr9qlj).ant-pagination
  .ant-pagination-jump-prev,
:where(.css-dev-only-do-not-override-1jr9qlj).ant-pagination
  .ant-pagination-jump-next {
  min-width: 20px;
}
:where(.css-dev-only-do-not-override-1jr9qlj).ant-pagination
  .ant-pagination-item-active
  a {
  color: white;
  background-color: #462aff;
  border: none;
  border-radius: 4px;
}

.pages-pagination{
  display: flex;
  justify-content: center;
  margin-top: 36px;
}

@media screen and (max-width:500px) {
  .pages-pagination{
    padding: 0 12px;
  }
}`, "",{"version":3,"sources":["webpack://./src/assets/css/pagination.css"],"names":[],"mappings":"AAAA;;EAEE,aAAa;AACf;AACA;;;;;;;;EAQE,eAAe;AACjB;AACA;;;EAGE,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE;IACE,eAAe;EACjB;AACF","sourcesContent":[":where(.css-dev-only-do-not-override-1jr9qlj).ant-pagination\n  .ant-pagination-options {\n  display: none;\n}\n:where(.css-dev-only-do-not-override-1jr9qlj).ant-pagination\n  .ant-pagination-prev,\n:where(.css-dev-only-do-not-override-1jr9qlj).ant-pagination\n  .ant-pagination-next,\n:where(.css-dev-only-do-not-override-1jr9qlj).ant-pagination\n  .ant-pagination-jump-prev,\n:where(.css-dev-only-do-not-override-1jr9qlj).ant-pagination\n  .ant-pagination-jump-next {\n  min-width: 20px;\n}\n:where(.css-dev-only-do-not-override-1jr9qlj).ant-pagination\n  .ant-pagination-item-active\n  a {\n  color: white;\n  background-color: #462aff;\n  border: none;\n  border-radius: 4px;\n}\n\n.pages-pagination{\n  display: flex;\n  justify-content: center;\n  margin-top: 36px;\n}\n\n@media screen and (max-width:500px) {\n  .pages-pagination{\n    padding: 0 12px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
