// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.lesson-result-container {
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.lesson-result-container.lesson-status{
  width: 60%;
  margin: 10px 0;
  display: flex;
  align-items: center;
}

.lesson-result-container .lesson-res-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

}

.lesson-result-container .lesson-res-content .circle-icon {
  position: relative;
  margin-top: 5px;
}

.lesson-result-container .lesson-res-content .circle-icon .filter-count {
  position: absolute;
  left: 20px;
}


@media screen and (max-width:850px) {
  .lesson-result-container.lesson-status{
    width: 100%;
  }
}

`, "",{"version":3,"sources":["webpack://./src/globalComponents/GlobalHead/LessonStatusResult/lessonResult.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;EAC9B,eAAe;AACjB;;AAEA;EACE,UAAU;EACV,cAAc;EACd,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;;AAEzB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,UAAU;AACZ;;;AAGA;EACE;IACE,WAAW;EACb;AACF","sourcesContent":[".lesson-result-container {\n  display: flex;\n  justify-content: space-between;\n  padding: 0 16px;\n}\n\n.lesson-result-container.lesson-status{\n  width: 60%;\n  margin: 10px 0;\n  display: flex;\n  align-items: center;\n}\n\n.lesson-result-container .lesson-res-content {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n\n}\n\n.lesson-result-container .lesson-res-content .circle-icon {\n  position: relative;\n  margin-top: 5px;\n}\n\n.lesson-result-container .lesson-res-content .circle-icon .filter-count {\n  position: absolute;\n  left: 20px;\n}\n\n\n@media screen and (max-width:850px) {\n  .lesson-result-container.lesson-status{\n    width: 100%;\n  }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
