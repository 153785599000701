// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.global-head-tabs {
}

.global-head-tabs {
  background: var(--neutrals-200, #ececec);
  padding: 16px 16px 0 16px;
  width: 100%;
  display: flex;
}
.global-head-tabs a {
  font-size: 16px;
  font-weight: 600;
  padding: 8px 24px;
  border-radius: 8px 8px 0px 0px;
  color: var(--neutrals-600);
  cursor: pointer;
}
.global-head-tabs a.active {
  background: #fff;
  color: var(--neutrals-900);
}

@media screen and (max-width: 500px) {
  .global-head-tabs a {
    font-size: 14px;
    font-weight: 700;
    padding: 8px 16px;
    border-radius: 8px 8px 0px 0px;
    color: var(--neutrals-600);
    cursor: pointer;
  }
}`, "",{"version":3,"sources":["webpack://./src/globalComponents/HeadTabs/headTabs.css"],"names":[],"mappings":"AAAA;AACA;;AAEA;EACE,wCAAwC;EACxC,yBAAyB;EACzB,WAAW;EACX,aAAa;AACf;AACA;EACE,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,8BAA8B;EAC9B,0BAA0B;EAC1B,eAAe;AACjB;AACA;EACE,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE;IACE,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,8BAA8B;IAC9B,0BAA0B;IAC1B,eAAe;EACjB;AACF","sourcesContent":[".global-head-tabs {\n}\n\n.global-head-tabs {\n  background: var(--neutrals-200, #ececec);\n  padding: 16px 16px 0 16px;\n  width: 100%;\n  display: flex;\n}\n.global-head-tabs a {\n  font-size: 16px;\n  font-weight: 600;\n  padding: 8px 24px;\n  border-radius: 8px 8px 0px 0px;\n  color: var(--neutrals-600);\n  cursor: pointer;\n}\n.global-head-tabs a.active {\n  background: #fff;\n  color: var(--neutrals-900);\n}\n\n@media screen and (max-width: 500px) {\n  .global-head-tabs a {\n    font-size: 14px;\n    font-weight: 700;\n    padding: 8px 16px;\n    border-radius: 8px 8px 0px 0px;\n    color: var(--neutrals-600);\n    cursor: pointer;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
