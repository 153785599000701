// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dropdown-name.data-status .dropdown-head {
  background-color: #fff;
}
.dropdown-name .dropdown-body ul li svg {
  position: absolute;
  left: 5px;
  top: 8px;
}
.dropdown-name .dropdown-body ul li {
  padding-left: 34px;
}

.dropdown-name .dropdown-body.room ul li {
  padding-left: 20px;
  font-size: 14px;
}
.dropdown-name .err-message {
  top: 49px;
  font-size: 11px;
  color: red;
  position: absolute;
}
.details-header-content .details-header-content-left .dropdown-name {
  margin-left: 12px;
  width: 236px;
}
@media screen and (max-width: 1320px) {
  .main-card-layout .dropdown-name .err-message {
    top: 39px;
  }
}

@media screen and (max-width: 500px) {
  .main-card-layout .dropdown-name .err-message {
    display: none;
  }
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/dropdownName.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;AACxB;AACA;EACE,kBAAkB;EAClB,SAAS;EACT,QAAQ;AACV;AACA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,eAAe;AACjB;AACA;EACE,SAAS;EACT,eAAe;EACf,UAAU;EACV,kBAAkB;AACpB;AACA;EACE,iBAAiB;EACjB,YAAY;AACd;AACA;EACE;IACE,SAAS;EACX;AACF;;AAEA;EACE;IACE,aAAa;EACf;AACF","sourcesContent":[".dropdown-name.data-status .dropdown-head {\n  background-color: #fff;\n}\n.dropdown-name .dropdown-body ul li svg {\n  position: absolute;\n  left: 5px;\n  top: 8px;\n}\n.dropdown-name .dropdown-body ul li {\n  padding-left: 34px;\n}\n\n.dropdown-name .dropdown-body.room ul li {\n  padding-left: 20px;\n  font-size: 14px;\n}\n.dropdown-name .err-message {\n  top: 49px;\n  font-size: 11px;\n  color: red;\n  position: absolute;\n}\n.details-header-content .details-header-content-left .dropdown-name {\n  margin-left: 12px;\n  width: 236px;\n}\n@media screen and (max-width: 1320px) {\n  .main-card-layout .dropdown-name .err-message {\n    top: 39px;\n  }\n}\n\n@media screen and (max-width: 500px) {\n  .main-card-layout .dropdown-name .err-message {\n    display: none;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
