// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page {
    /* margin-top: 56px; */
    padding-bottom: 30px;
    height: 50vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.error-page h1 {
    color: rgb(240, 21, 21);
    font-size: 35px;
    margin-bottom: 20px;
}
.error-page svg {
    width: 80px;
    height: 80px;
}
.error-page svg path {
    fill: rgb(240, 21, 21);
}`, "",{"version":3,"sources":["webpack://./src/Pages/ErrorPage/error.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,oBAAoB;IACpB,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,uBAAuB;IACvB,eAAe;IACf,mBAAmB;AACvB;AACA;IACI,WAAW;IACX,YAAY;AAChB;AACA;IACI,sBAAsB;AAC1B","sourcesContent":[".error-page {\n    /* margin-top: 56px; */\n    padding-bottom: 30px;\n    height: 50vh;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.error-page h1 {\n    color: rgb(240, 21, 21);\n    font-size: 35px;\n    margin-bottom: 20px;\n}\n.error-page svg {\n    width: 80px;\n    height: 80px;\n}\n.error-page svg path {\n    fill: rgb(240, 21, 21);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
