// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

.questions-container{
    padding: 40px;
}

 
 .questions-container .question-card{
    margin: 10px 0;
    padding: 16px;
}

.questions-container .question-card .question-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.questions-container .question-card .question-header h3{
    color: #65C2F6;
    font-size: 24px;
    font-weight: 600;
}

.questions-container .question-card .question-options-container{
    margin: 10px 0;
}

.questions-container .question-card .question-options-container .question-options-container h5 {
    color: #7586AC;
    font-size: 20px;
    font-weight: 400;
}

.questions-container .question-card .question-options-container .question-options-list{
    display: flex;
    align-items: center;
}

.questions-container .question-card .question-options-container h5 {
    color: #7586AC;
    font-size: 20px;
    font-weight: 400;
    margin-left: 5px;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/pages/questionPage.css"],"names":[],"mappings":";;AAEA;IACI,aAAa;AACjB;;;CAGC;IACG,cAAc;IACd,aAAa;AACjB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;IAChB,gBAAgB;AACpB","sourcesContent":["\n\n.questions-container{\n    padding: 40px;\n}\n\n \n .questions-container .question-card{\n    margin: 10px 0;\n    padding: 16px;\n}\n\n.questions-container .question-card .question-header {\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.questions-container .question-card .question-header h3{\n    color: #65C2F6;\n    font-size: 24px;\n    font-weight: 600;\n}\n\n.questions-container .question-card .question-options-container{\n    margin: 10px 0;\n}\n\n.questions-container .question-card .question-options-container .question-options-container h5 {\n    color: #7586AC;\n    font-size: 20px;\n    font-weight: 400;\n}\n\n.questions-container .question-card .question-options-container .question-options-list{\n    display: flex;\n    align-items: center;\n}\n\n.questions-container .question-card .question-options-container h5 {\n    color: #7586AC;\n    font-size: 20px;\n    font-weight: 400;\n    margin-left: 5px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
