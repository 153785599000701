// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.salary-table tr td:first-child,
.salary-table tr th:first-child {
  width: 30%;
}
.salary-table tr th:nth-last-child(2),
.salary-table tr td:nth-last-child(2),
.salary-table tr th:nth-last-child(3),
.salary-table tr td:nth-last-child(3),
.salary-table tr th:last-child,
.salary-table tr td:last-child {
  text-align: center;
}

.salary-table tr th:nth-child(3) {
  width: 200px;
}
.salary-table tr td:nth-child(3) {
  cursor: pointer;
}

/* .salary-table tr th:nth-child(5),
.salary-table tr td:nth-child(5){
  width: 10%;
  overflow: hidden;
  text-overflow: ellipsis;
} */

/* .salary-table tr th:nth-child(7),
.salary-table tr td:nth-child(7){
  text-decoration: none;
  width: 100px;
  cursor: pointer;
} */

/* .salary-table tr td svg:nth-last-child(1){
  margin-left: 5px;
} */

.salary-table td:nth-last-child(1) {
  cursor: pointer;
}

.salary-bonus-modal-tablet {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  position: fixed;
  height: 100%;
  border: 1px solid;
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  top: 0;
  right: 0;
}
.salary-bonus-modal-tablet .salary-plus-icon {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/assets/css/pages/salaryPage.css"],"names":[],"mappings":"AAAA;;EAEE,UAAU;AACZ;AACA;;;;;;EAME,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;AACA;EACE,eAAe;AACjB;;AAEA;;;;;GAKG;;AAEH;;;;;GAKG;;AAEH;;GAEG;;AAEH;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,YAAY;EACZ,eAAe;EACf,YAAY;EACZ,iBAAiB;EACjB,oCAAoC;EACpC,WAAW;EACX,MAAM;EACN,QAAQ;AACV;AACA;EACE,aAAa;AACf","sourcesContent":[".salary-table tr td:first-child,\n.salary-table tr th:first-child {\n  width: 30%;\n}\n.salary-table tr th:nth-last-child(2),\n.salary-table tr td:nth-last-child(2),\n.salary-table tr th:nth-last-child(3),\n.salary-table tr td:nth-last-child(3),\n.salary-table tr th:last-child,\n.salary-table tr td:last-child {\n  text-align: center;\n}\n\n.salary-table tr th:nth-child(3) {\n  width: 200px;\n}\n.salary-table tr td:nth-child(3) {\n  cursor: pointer;\n}\n\n/* .salary-table tr th:nth-child(5),\n.salary-table tr td:nth-child(5){\n  width: 10%;\n  overflow: hidden;\n  text-overflow: ellipsis;\n} */\n\n/* .salary-table tr th:nth-child(7),\n.salary-table tr td:nth-child(7){\n  text-decoration: none;\n  width: 100px;\n  cursor: pointer;\n} */\n\n/* .salary-table tr td svg:nth-last-child(1){\n  margin-left: 5px;\n} */\n\n.salary-table td:nth-last-child(1) {\n  cursor: pointer;\n}\n\n.salary-bonus-modal-tablet {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  z-index: 100;\n  position: fixed;\n  height: 100%;\n  border: 1px solid;\n  background-color: rgba(0, 0, 0, 0.5);\n  width: 100%;\n  top: 0;\n  right: 0;\n}\n.salary-bonus-modal-tablet .salary-plus-icon {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
