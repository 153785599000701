import { ReactComponent as CloseIcon } from "../../../assets/icons/more-modal/x-close.svg";

const SalaryMoreModal = ({ salary, setOpenSalaryModal }) => {
  console.log(salary, "salary,,,");

  const salaryHead = [
    { id: 1, label: "Tələbə adı" },
    { id: 2, label: "Qrup" },
    { id: 3, label: "Dərs sayı" },
    { id: 4, label: "Ödəyəcəyi məbləğ" },
    { id: 5, label: "Ümumi məbləğ" },
  ];
  return (
    <div className="more-modal salary ">
      <div className="more-modal-con">
        <div className="more-modal-header">
          <h2>{salary?.teacher?.fullName}</h2>
          <div className="more-modal-header-icons">
            <div className="header-icon-close">
              <CloseIcon onClick={() => setOpenSalaryModal(false)} />
            </div>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              {salaryHead?.map((salary) => (
                <th key={salary.id}>{salary.label}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {salary?.salariesListEveryStudent?.map((salary, i) => (
              <tr key={i}>
                <td>{salary.student.fullName}</td>
                <td>{salary?.group?.groupNumber}</td>
                <td>{salary?.lessonsCount}</td>
                <td>{salary?.studentPaymentForTeacher}</td>
                <td>{salary?.studentTotalPayment}</td>
              </tr>
            ))}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan="3">Cəmi</td>
              <td>{salary.salary} AZN</td>
              <td></td>
            </tr>
          </tfoot>
        </table>
      </div>
    </div>
  );
};

export default SalaryMoreModal;
