// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.how-to-use-search {
  margin-top: 28px;
}
.how-to-use-search {
  border-radius: 8px;
  background: var(--neutrals-100);
}
.how-to-use-search .input-box {
  display: flex;
  padding: 6px 12px;
  align-items: center;
}
.how-to-use-search .input-box input {
  border-radius: 8px;
  font-size: 16px;
  font-weight: 600;
  background: var(--neutrals-100);
  margin-left: 20px;
  /* width: 236px; */
}
`, "",{"version":3,"sources":["webpack://./src/globalComponents/HowToUse/components/Search/search.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;AACA;EACE,kBAAkB;EAClB,+BAA+B;AACjC;AACA;EACE,aAAa;EACb,iBAAiB;EACjB,mBAAmB;AACrB;AACA;EACE,kBAAkB;EAClB,eAAe;EACf,gBAAgB;EAChB,+BAA+B;EAC/B,iBAAiB;EACjB,kBAAkB;AACpB","sourcesContent":[".how-to-use-search {\n  margin-top: 28px;\n}\n.how-to-use-search {\n  border-radius: 8px;\n  background: var(--neutrals-100);\n}\n.how-to-use-search .input-box {\n  display: flex;\n  padding: 6px 12px;\n  align-items: center;\n}\n.how-to-use-search .input-box input {\n  border-radius: 8px;\n  font-size: 16px;\n  font-weight: 600;\n  background: var(--neutrals-100);\n  margin-left: 20px;\n  /* width: 236px; */\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
