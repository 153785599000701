// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expenses-page-head{
    display: flex;
    align-items: center;
}


.expenses-page-head .global-category-dropdown .dropdown-head{
    background-color: white;
    margin: 0 5px;
}

.expenses-page-head .global-category-dropdown{
    width: 200px;
}

@media screen and (max-width:1400px) {
    .expenses-page-head{
        width: 100%;
    }
   
}`, "",{"version":3,"sources":["webpack://./src/globalComponents/GlobalHead/ExpensesPageHead/expenses-head.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;AACvB;;;AAGA;IACI,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI;QACI,WAAW;IACf;;AAEJ","sourcesContent":[".expenses-page-head{\n    display: flex;\n    align-items: center;\n}\n\n\n.expenses-page-head .global-category-dropdown .dropdown-head{\n    background-color: white;\n    margin: 0 5px;\n}\n\n.expenses-page-head .global-category-dropdown{\n    width: 200px;\n}\n\n@media screen and (max-width:1400px) {\n    .expenses-page-head{\n        width: 100%;\n    }\n   \n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
